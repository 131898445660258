<template>
  <div>
    <!-- Page Content -->
    <div class="content">
      <!-- Full Table -->
      <base-block title="Detalii" v-bind:subtitle="product.name">
        <b-row class="row-deck">
          <b-col md="4">
            <base-block title="Imagini" content-full>
              <div v-if="dataLoad" class="text-center">
                <b-spinner variant="primary" label="Loading..."></b-spinner>
              </div>
              <gallery v-if="!dataLoad && productImages.length === images.length" :images="productImages" :index="index" :options="{ indicatorOptions: { thumbnailIndicators: true }}" @close="index = null"></gallery>
              <div class="text-center">
                <a class="img-link img-link-zoom-in img-thumb" href="javascript:void(0)" @click="index = 0">
                  <img class="img-fluid" :src="productImages[0]" :alt="product.description">
                </a>
              </div>
              <b-row class="items-push d-none">
                <b-col md="6" lg="4" xl="3" class="animated fadeIn" v-for="(photo, photoIndex) in productImages" :key="photoIndex">
                  <a class="img-link img-link-zoom-in img-thumb" href="javascript:void(0)" @click="index = photoIndex">
                    <img class="img-fluid" :src="photo" :alt="product.description">
                  </a>
                </b-col>
              </b-row>
              <div v-if="!dataLoad && productImages[0] && 0 > productImages[0].indexOf('no-image')" class="text-center">
                <strong>{{productImages.length}}</strong>
                <span v-if="productImages.length < 2"> imagine</span>
                <span v-if="productImages.length > 1"> imagini</span>
              </div>
            </base-block>
          </b-col>
          <b-col md="8">
            <base-block title="Specificatii" content-full>
              <div v-if="dataLoad" class="text-center">
                <b-spinner variant="primary" label="Loading..."></b-spinner>
              </div>
              <b-table-simple v-if="!dataLoad" responsive bordered striped table-class="table-vcenter">
                <b-tbody>
                  <b-tr>
                    <b-td><strong>Categorie</strong></b-td>
                    <b-td><span class="text-dark font-weight-bolder">{{ product.category ? product.category.name : '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><strong>Denumire</strong></b-td>
                    <b-td><span class="text-primary">{{product.name}}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><strong>Descriere</strong></b-td>
                    <b-td><span class="text-muted">{{product.description}}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><strong>Cod Produs</strong></b-td>
                    <b-td><span class="text-dark font-weight-bolder">{{product.code}}</span></b-td>
                  </b-tr>
                  <b-tr v-for="property in attributes" v-bind:key="property.attribute">
                    <b-td><strong>{{property.label}}</strong></b-td>
                    <b-td>{{property.value}}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </base-block>
          </b-col>
        </b-row>
        <div v-if="dataLoad" class="text-center">
          <b-spinner variant="primary" label="Loading..."></b-spinner>
        </div>
        <base-block v-if="!dataLoad && compatibleLaptops" title="Laptopuri compatibile" content-full class="bordered-block">
          <b-badge v-for="cl in compatibleLaptops" v-bind:key="cl+Math.random().toString()" variant="info" class="mr-2">{{cl}}</b-badge>
        </base-block>
        <base-block v-if="!dataLoad && compatibleCodes" title="Coduri compatibile" content-full class="bordered-block">
          <b-badge v-for="cc in compatibleCodes" v-bind:key="cc+Math.random().toString()" variant="info" class="mr-2">{{cc}}</b-badge>
        </base-block>
      </base-block>
    </div>
    <!-- END Page Content -->
  </div>
</template>

<script>
import VueGallery from "vue-gallery";
import {PRODUCT_DETAIL} from "../../../constants/product-graphql";
import {GC_AUTH_TOKEN} from "../../../constants/settings";

export default {
  name: "ProductPublicDetail",
  components: {'gallery': VueGallery},
  data() {
    return {
      productId: atob(this.$route.params.id),
      product: {},
      noImage: require('../../../assets/no-image.png'),
      dataLoad: true,
      productImages: [],
      index: null,
    }
  },
  computed: {
    images() {
      if (this.product.properties) {
        let images = [];
        this.product.properties.forEach(function(item) {
          if (item.attribute.indexOf('imagine') >= 0 && item.attribute.indexOf('imagine_data') < 0) {
            images.push(process.env.VUE_APP_IMG_URL+item.value);
          }
        });
        if (images.length) {
          return images;
        }
      }

      return [this.noImage];
    },
    attributes() {
      const deniedAttributes = ['stoc', 'subcategorie', 'imagine', 'laptopuri_compatibile', 'coduri_compatibile'];
      let propertiesToShow = [];
      if (this.product.properties) {
        this.product.properties.forEach(function (item) {
          let property = item.attribute;
          let availableAttribute = true;
          deniedAttributes.forEach(function (deniedAttribute) {
            if (property.toLowerCase().indexOf(deniedAttribute) >= 0 || item.value === "") {
              availableAttribute = false;
            }
          });
          if (availableAttribute) {
            propertiesToShow.push(item);
          }
        });
      }

      return propertiesToShow;
    },
    compatibleLaptops() {
      let compatibleLaptops = null;
      if (this.product.properties) {
        this.product.properties.forEach(function (item) {
          if (item.attribute.toLowerCase() === 'laptopuri_compatibile') {
            compatibleLaptops = item.value;
          }
        });
      }

      return this.formatArrayValues(compatibleLaptops);
    },
    compatibleCodes() {
      let compatibleCodes = null;
      if (this.product.properties) {
        this.product.properties.forEach(function (item) {
          if (item.attribute.toLowerCase() === 'coduri_compatibile') {
            compatibleCodes = item.value;
          }
        });
      }

      return this.formatArrayValues(compatibleCodes);
    }
  },
  apollo: {
    itemProduct: {
      query: PRODUCT_DETAIL,
      fetchPolicy: "no-cache",
      variables () {
        return {
          id: this.productId,
        }
      },
      result(result) {
        this.product = result.data.itemProduct;
        this.dataLoad = false;
      }
    }
  },
  methods: {
    formatArrayValues(strValue) {
      if (!strValue) {
        return strValue;
      }
      strValue = strValue.replaceAll(';', ',');
      strValue = strValue.replaceAll('and ', ',');
      strValue = strValue.replaceAll(', ', ',');
      if (strValue.length > 30 && strValue.indexOf('Equium') > 0) {
        strValue = strValue.replaceAll('Equium', ',Equium');
      }
      if (strValue.length > 40) {
        strValue = strValue.replaceAll(' ', ',');
      }

      return strValue.split(',');
    },
  },
  mounted() {
    const currentUser = localStorage.getItem(GC_AUTH_TOKEN);
    if (typeof(currentUser) !== 'undefined' && currentUser !== null) {
      this.$router.push('/product/detail/'+this.$route.params.id);
    }
  },
  created() {
    const currentUser = localStorage.getItem(GC_AUTH_TOKEN);
    if (typeof(currentUser) !== 'undefined' && currentUser !== null) {
      this.$router.push('/product/detail/'+this.$route.params.id);
    }
  }
}
</script>